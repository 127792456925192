import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from '../views/Dashboard';
import Category from '../views/Category';
import clsx from 'clsx';
import Sidebar from '../components/layout/Sidebar';
import Appbar from '../components/layout/Appbar';
import demoUserIcon from '../assets/demoUserIcon.png';
import HomeIcon from '../assets/icons/Home.svg';
import ID1Icon from '../assets/icons/Happiness.svg';
import ID2Icon from '../assets/icons/Reflection.svg';
import ID7Icon from '../assets/icons/Activity.svg';
import ID6Icon from '../assets/icons/Ticket.svg';
import ID9Icon from '../assets/icons/3 User.svg';
import TicketIcon from '../assets/icons/Ticket.svg';
import UserIcon from '../assets/icons/3 User.svg';
import GetStartedIcon from '../assets/icons/play_button.svg'
import ID4Icon from '../assets/icons/Star.svg';
import ID8Icon from '../assets/icons/Shield Done.svg';
import ID3Icon from '../assets/icons/Heart.svg';
import ID5Icon from '../assets/icons/Filter.svg';
import ID10Icon from '../assets/icons/Document.svg';
import IDGeneralIcon from '../assets/icons/category.png'
import DocumentIcon from '../assets/icons/Document.svg';
import MySessionIcon from '../assets/icons/MySessions.svg';
import DownloadIcon from '../assets/icons/download.svg';
import { Affiliate } from '../components/navigation/AffiliateV2';
import ReferalAnalysisV2 from '../components/referalAnalysis/ReferralAnalysisV2';
import { PayoutLedgerV2 } from '../components/UserManagement/payoutLedgerV2';
import { PaymentLedgerV2 } from '../components/UserManagement/ledgerV2';
import AdminDashboardV2 from '../components/AdminDashboard/AdminDashboardV2';
import UsersV2 from '../components/navigation/UsersV2';
import { AnalyticsDashboard } from '../components/analytics/AnalyticsDashboardV2';
import Play from '../views/categoryView/Play';
import SettingV2 from '../components/navigation/SettingV2';
import SessionsV3 from "../components/navigation/SessionsV3";
import ImagePlayerV2 from "../components/imagePlayer/imagePlayerV2";
import UpgradeAccountV2 from "../components/navigation/UpgradeAccountV2";
import EditSession from '../v2Sessions/EditSession';
import UpgradeAccountLife from '../components/navigation/UpgradeAccountLife';
import UpgradeAccountLifeV2 from '../components/navigation/UpgradeAccountLifeV2';
import { SessionCurator } from '../components/navigation/SessionCurator';
import { ManualFeaturedSessions } from '../components/navigation/ManualFeaturedSessions';
import { ExperienceBuilder } from "../components/navigation/ExperienceBuilder";
import { BiMedal } from 'react-icons/bi';
import Invites from '../components/invites/invites';
import UserManagement from '../components/navigation/UserManagement';
import UpgradeAccountV3 from '../components/navigation/UpgradeAccountV3';
import ErrorPage from '../views/notFound';
import AdminProRoute from "./AdminProRoute";
import CoachingRoute from "./CoachingRoute";
import UpgradeRoute from './UpgradeRoute';
import InviteRoute from './InviteRoute';
import RecentSessions from '../components/navigation/RecentSessions';
import Tutorial from '../views/tutorials';
import ServiceUnavailable from '../views/ServiceUnavailable';
import MusicCenter from '../components/navigation/MusicCenter';
import PersonalizeImages from '../components/personalizeImages/PersonalizeImages';
import GeneratedZips from '../components/navigation/GeneratedZips';
import CreateSession from '../components/navigation/CreateSession';
import CategoryAdmin from '../components/navigation/CategoryAdmin';
import CoachingSessions from '../components/CoachingSessions/CoachingSessions';
import PlayCoachingSessions from '../components/CoachingSessions/PlaySession';
import CreateCoachingSessions from '../components/navigation/CreateCoachingSessions';
import PaymentPlans from '../components/navigation/PaymentPlans';
import ScrollToTop from './ScrollToTop';
import { subscriptionPlans } from '../components/CoachingSessions/CoachingPlans';
import Downloads from '../views/Downloads';
import { useSelector } from 'react-redux';
import InviteReport  from '../components/navigation/InviteReport';
import MembershipTypeReports from '../components/navigation/MembershipTypeReports';
import GeneralReports from '../components/navigation/GeneralReports';

const demoAppBarData = {
    quote: "'It's never too late to be what you might've been.” | George Eliot.'",
    userphoto: demoUserIcon,
    username: 'Lina Rolland'
}

// const V2_PREFIX = '/v2'
const V2_PREFIX = ''
export const ROUTES = {
    CATEGORY: V2_PREFIX + '/category',
    DASHBOARD: V2_PREFIX + '/dashboard',
    DOWNLOADS: V2_PREFIX + '/downloads',
    EARN: V2_PREFIX + '/earn',
    SETTINGS: V2_PREFIX + '/settings',
    IMAGEPLAYER: V2_PREFIX + '/wellness/play/:id',
    REFERRAL: V2_PREFIX + '/referral',
    REFERRALGRAPH: V2_PREFIX + '/referralGraphs',
    LEDGER: V2_PREFIX + '/ledger',
    PAYOUT: V2_PREFIX + '/payoutledger',
    ADMINDASHBOARD: V2_PREFIX + '/admindash',
    ANALYTICS: V2_PREFIX + '/analytics',
    CLIENTMANAGEMENT: V2_PREFIX + '/users',
    MYSESSIONS: V2_PREFIX + '/sessions',
    IMAGES: V2_PREFIX + '/images/:_id',
    UPGRADEACCOUNT: V2_PREFIX + '/upgradeAccount',
    SESSIONCURATOR: V2_PREFIX + '/session/curator',
    MANUALFEATUREDSESSION: V2_PREFIX + '/setFeaturedSessions',
    EXPERIENCEBUILDER: V2_PREFIX + "/session/experienceBuilder",
    INVITES: V2_PREFIX + '/invites',
    USERADMINISTRATOR: V2_PREFIX + '/user_administration',
    RECENTSESSIONS: V2_PREFIX + '/watched_sessions',
    TUTORIALS: V2_PREFIX + '/tutorial',
    MUSICCENTER: V2_PREFIX + '/audio_list',
    BONUSES: V2_PREFIX + '/bonuses',
    PERSONALIZEIMAGES: V2_PREFIX + '/personalize',
    SESSIONEXPORTS: V2_PREFIX + '/session_exports',
    SESSIONCREATE: V2_PREFIX + '/create_session',
    CATEGORYADMIN: V2_PREFIX + '/category_admin',
    COACHINGSESSIONS: V2_PREFIX + '/coaching_pearls',
    PLAYCOACHINGSESSIONS: V2_PREFIX + '/play_coaching_session',
    CREATECOACHINGSESSIONS: V2_PREFIX + '/create_coaching_session',
    PAYMENTPLANS: V2_PREFIX + '/payment_plans',
    FREERESOURCES: V2_PREFIX + 'free-resources/',
    STORIES: V2_PREFIX + 'stories-2/',
    INVITEREPORT: V2_PREFIX + '/invite_report',
    GERERALREPORTS: V2_PREFIX + '/general_reports',
    MEMBERSHIPTYPEREPORT: V2_PREFIX + '/membership_type_report',
}

function DashboardRouter(props) {

    const [openSidebar, setOpenSidebar] = useState(false);
    const icons = [ID1Icon, ID2Icon, ID3Icon, ID4Icon, ID5Icon, ID6Icon, ID7Icon, ID8Icon, ID9Icon, ID10Icon]
    const networkStatus = useSelector(state => state.networkStatus)

    let categories = []
    props.categories.filter(obj => obj.status === "enabled").map((item, index) => {
        categories.push({
            icon: icons[index] ? icons[index] : IDGeneralIcon,
            id: item.id,
            name: item.name
        })
    })
    categories.sort((a, b) => (a.id > b.id) ? 1 : -1)

    categories.push({
        icon: UserIcon,
        id: 'top10',
        name: 'Top 10 Sessions'
    })

    let NAV_ITEMS_COMMON = [
        {
            icon: HomeIcon,
            label: 'Dashboard',
            link: ROUTES.DASHBOARD
        },
        {
            icon: DownloadIcon,
            label: 'Downloads',
            link:  ROUTES.DOWNLOADS,
            isAvailableOffline: true
        },
        {
            icon: GetStartedIcon,
            label: 'Get Started',
            link: ROUTES.TUTORIALS
        },
        {
            icon: GetStartedIcon,
            label: 'Free Resources',
            type: 'OUTER_ROUTE_FREERESOURCES',
            link: ROUTES.FREERESOURCES
        },
        {
            icon: GetStartedIcon,
            label: 'Coaching Pearls',
            link: ROUTES.COACHINGSESSIONS
        },
        {
            icon: GetStartedIcon,
            label: 'Stories',
            type: 'OUTER_ROUTE_STORIES',
            link: ROUTES.STORIES
        },
        {
            icon: GetStartedIcon,
            label: 'Bonuses',
            type: 'OUTER_ROUTE',
            link: ROUTES.BONUSES
        },
        {
            icon: UserIcon,
            label: 'Top 10 Sessions',
            link: ROUTES.CATEGORY + '/' + 'top10'
        },
        {
            icon: MySessionIcon,
            label: 'My Sessions',
            link: ROUTES.MYSESSIONS + '/' + 'liked'
        },
        {
            icon: DocumentIcon,
            label: 'My Gallery',
            link: ROUTES.PERSONALIZEIMAGES
        },
        ...categories.slice(0, categories.length - 1).map((category) => { return { label: category.name, icon: category.icon, link: ROUTES.CATEGORY + '/' + category.id } }),
        {
            icon: TicketIcon,
            label: 'Earn $$',
            link: ROUTES.EARN,
            child: [{
                icon: TicketIcon,
                label: 'Referral / Affiliate',
                link: ROUTES.REFERRAL
            },
            {
                icon: TicketIcon,
                label: 'Referral Analytics',
                link: ROUTES.REFERRALGRAPH
            },]
        },
        {
            icon: DocumentIcon,
            label: 'Settings',
            link: ROUTES.SETTINGS
        }
    ]
    
    // PP-339 Hiding the coaching pearls tab if susbscriptionType is not present in subscriptionPlans
    // Coaching pearls tab is present on position 3
    if (props.user.role.id !== 1 && props.user.role.id !== 4) {
        if (!subscriptionPlans.includes(props.user.subscriptionType) ) {
            NAV_ITEMS_COMMON.splice(3, 1)
        }   
    }

    let NAV_ITEMS_COACH = [{
        icon: UserIcon,
        label: 'Analytics',
        link: ROUTES.ANALYTICS
    },
    {
        icon: UserIcon,
        label: 'Client Management',
        link: ROUTES.CLIENTMANAGEMENT
    },]

    let NAV_ITEMS_ADMIN = [{
        icon: UserIcon,
        label: 'Sales Ledger',
        link: ROUTES.LEDGER
    },
    {
        icon: UserIcon,
        label: 'Payout Ledger',
        link: ROUTES.PAYOUT
    },
    {
        icon: UserIcon,
        label: 'Dashboard',
        link: ROUTES.ADMINDASHBOARD
    },
    {
        icon: UserIcon,
        label: 'User Administrator',
        child: [{
            icon: TicketIcon,
            label: 'Invite Report',
            link: ROUTES.INVITEREPORT
        },
        {
            icon: TicketIcon,
            label: 'General Reports',
            link: ROUTES.GERERALREPORTS
        },{
            icon: TicketIcon,
            label: 'Membership Types',
            link: ROUTES.MEMBERSHIPTYPEREPORT
        },
        {
            icon: TicketIcon,
            label: 'User Management',
            link: ROUTES.USERADMINISTRATOR
        },]
        // link: ROUTES.USERADMINISTRATOR
    },
    {
        icon: UserIcon,
        label: 'Sessions',
        child: [{
            icon: UserIcon,
            label: 'Session Curator',
            link: ROUTES.SESSIONCURATOR
        },
        {
            icon: UserIcon,
            label: 'Feature Sessions',
            link: ROUTES.MANUALFEATUREDSESSION
        },
        {
            icon: UserIcon,
            label: "Experience Builder",
            link: ROUTES.EXPERIENCEBUILDER,
        },
        {
            icon: UserIcon,
            label: "Music Center",
            link: ROUTES.MUSICCENTER,
        },
        {
            icon: UserIcon,
            label: "Session Exports",
            link: ROUTES.SESSIONEXPORTS
        },
        {
            icon: UserIcon,
            label: "Create Session",
            link: ROUTES.SESSIONCREATE
        },
        {
            icon: UserIcon,
            label: "Category Admin",
            link: ROUTES.CATEGORYADMIN
        },
        {
            icon: UserIcon,
            label: "Create Coaching Sessions",
            link: ROUTES.CREATECOACHINGSESSIONS
        }
        ]
    },
    {
        icon: UserIcon,
        label: "Payment Plans",
        link: ROUTES.PAYMENTPLANS
    }
    ]

    if (
        props.user && (
            props.user.role.id === 1 ||
            props.user.role.id === 2 ||
            props.user.role.id === 3 ||
            props.user.role.id === 4 ||
            props.user.role.id === 5 ||
            props.user.role.id === 6 ||
            props.user.role.id === 7 ||
            props.user.invite_parent ||
            ["30DayChallengeToUpgrade", "30daychallengeauto", "signature-monthly", "signature-monthly-free10trial", "signature7-free7trial", "pro-monthly"].includes( props.user.subscriptionType )
        )) {
        NAV_ITEMS_COMMON.push({
            icon: UserIcon,
            label: 'Upgrade Account',
            link: ROUTES.UPGRADEACCOUNT
        })
    }

    if (!networkStatus.connected) {
        NAV_ITEMS_COMMON = NAV_ITEMS_COMMON.filter(f => f.isAvailableOffline)
        NAV_ITEMS_ADMIN = NAV_ITEMS_ADMIN.filter(f => f.isAvailableOffline)
        NAV_ITEMS_COACH = NAV_ITEMS_COACH.filter(f => f.isAvailableOffline)
    }

    const resizeWindow = (height) => {
        document.querySelector(".sidebarContainer").style.height = height+"px";
        document.querySelector(".sidebarContainer").style.overflow = "scroll";
        document.querySelector(".mainContainer").style.height = height+"px";
        document.querySelector(".mainContainer").style.overflow = "scroll";
    }

    useEffect(() => {
        let height = window.innerHeight;
        resizeWindow(height)
    },[])
    window.addEventListener('resize',(e) => {
        let height = e.target.innerHeight;
        resizeWindow(height)
    })
    return (
        <div className={clsx('container-fluid', 'dashboardContainer')}>
            <div className={clsx('sidebarContainer', openSidebar && 'sidebarContainerOpen')}>
                <Sidebar
                    onCloseSidebar={() => setOpenSidebar(false)}
                    navItems={{ NAV_ITEMS_COMMON, NAV_ITEMS_ADMIN, NAV_ITEMS_COACH }}
                    user={props.user}
                />
            </div>
            <div className={'mainContainer'}>
                <div className={'appbarContainer'}>
                    <Appbar
                        onOpenSidebar={() => setOpenSidebar(true)}
                        isSidebarOpen={openSidebar}
                        {...demoAppBarData}
                        user={props.user}
                    />
                </div>
                <div className={'mainLayoutContainer'} onClick={() => setOpenSidebar(false)}>
                    {/* {renderInvites(props)} */}
                    {/* {renderUpgradeAccount(props)} */}
                    {/* {renderProArea(props)} */}
                    {/* {renderAdminArea(props)} */}
                    {/* <ScrollToTop/> */}
                    <Switch>
                        <AdminProRoute
                            exact
                            path={ROUTES.LEDGER}
                            requiredRoles={[1]}
                            user={props.user}
                            component={PaymentLedgerV2}
                        />
                        <AdminProRoute
                            exact
                            path={ROUTES.PAYOUT}
                            requiredRoles={[1]}
                            user={props.user}
                            component={PayoutLedgerV2}
                        />
                        <AdminProRoute
                            exact
                            path={ROUTES.ADMINDASHBOARD}
                            requiredRoles={[1]}
                            user={props.user}
                            component={AdminDashboardV2}
                        />
                        <AdminProRoute
                            exact
                            path={ROUTES.USERADMINISTRATOR}
                            requiredRoles={[1]}
                            user={props.user}
                            component={UserManagement}
                        />
                        <AdminProRoute
                            exact
                            path={ROUTES.INVITEREPORT}
                            requiredRoles={[1]}
                            user={props.user}
                            component={InviteReport}
                        />
                        <AdminProRoute
                            exact
                            path={ROUTES.GERERALREPORTS}
                            requiredRoles={[1]}
                            user={props.user}
                            component={GeneralReports}
                        />
                        <AdminProRoute
                            exact
                            path={ROUTES.MEMBERSHIPTYPEREPORT}
                            requiredRoles={[1]}
                            user={props.user}
                            component={MembershipTypeReports}
                        />
                        <AdminProRoute
                            exact
                            path={ROUTES.SESSIONCURATOR}
                            requiredRoles={[1]}
                            user={props.user}
                            component={SessionCurator}
                        />
                        <AdminProRoute
                            exact
                            path={ROUTES.MANUALFEATUREDSESSION}
                            requiredRoles={[1]}
                            user={props.user}
                            component={ManualFeaturedSessions}
                        />

                        <AdminProRoute
                            exact
                            path={ROUTES.ANALYTICS}
                            requiredRoles={[1, 4]}
                            user={props.user}
                            component={AnalyticsDashboard}
                        />
                        <AdminProRoute
                            exact
                            path={ROUTES.CLIENTMANAGEMENT}
                            requiredRoles={[1, 4]}
                            user={props.user}
                            component={UsersV2}
                        />

                        <AdminProRoute
                            exact
                            path={ROUTES.LEDGER}
                            requiredRoles={[1]}
                            user={props.user}
                            component={PaymentLedgerV2}
                        />

                        <AdminProRoute
                            exact
                            path={ROUTES.EXPERIENCEBUILDER}
                            requiredRoles={[1]}
                            user={props.user}
                            component={ExperienceBuilder}
                        />

                        <AdminProRoute
                            exact
                            path={ROUTES.MUSICCENTER}
                            requiredRoles={[1]}
                            user={props.user}
                            component={MusicCenter}
                        />

                        <AdminProRoute
                            exact
                            path={ROUTES.SESSIONEXPORTS}
                            requiredRoles={[1]}
                            user={props.user}
                            component={GeneratedZips}
                        />

                        <AdminProRoute
                            exact
                            path={ROUTES.SESSIONCREATE}
                            requiredRoles={[1]}
                            user={props.user}
                            component={CreateSession}
                        />

                        <AdminProRoute
                            exact
                            path={ROUTES.CATEGORYADMIN}
                            requiredRoles={[1]}
                            user={props.user}
                            component={CategoryAdmin}
                        />
                        <AdminProRoute
                            exact
                            path={ROUTES.CREATECOACHINGSESSIONS}
                            requiredRoles={[ 1 ]}
                            user={props.user}
                            component={CreateCoachingSessions}
                        />
                        <AdminProRoute
                            exact
                            path={ROUTES.PAYMENTPLANS}
                            requiredRoles={[ 1 ]}
                            user={props.user}
                            component={PaymentPlans}
                        />
                        <UpgradeRoute
                            exact
                            path={'/upgradeAccountLife'}
                            requiredRoles={[1, 2, 7]}
                            requiredSubscriptionType={["30DayChallengeToUpgrade", "30daychallengeauto"]}
                            user={props.user}
                            component={UpgradeAccountLifeV2}
                        />

                        <UpgradeRoute
                            exact
                            path={'/upgradeAccountv2'}
                            requiredRoles={[1, 2, 7]}
                            requiredSubscriptionType={["30DayChallengeToUpgrade", "30daychallengeauto"]}
                            user={props.user}
                            component={UpgradeAccountV2}
                        />

                        <UpgradeRoute
                            exact
                            path={ROUTES.UPGRADEACCOUNT}
                            requiredRoles={[1, 2, 3, 4, 5, 6, 7]}
                            requiredSubscriptionType={["30DayChallengeToUpgrade", "30daychallengeauto", "signature-monthly", "signature-monthly-free10trial", "signature7-free7trial", "pro-monthly"]}
                            user={props.user}
                            component={UpgradeAccountV3}
                        />

                        <InviteRoute
                            exact
                            path={ROUTES.INVITES}
                            requiredRoles={[1, 3, 4, 5, 6]}
                            user={props.user}
                            component={Invites}
                        />

                        <Route
                            exact path="/"
                            component={Dashboard}
                        />

                        <CoachingRoute
                            exact
                            path={ROUTES.COACHINGSESSIONS}
                            user={props.user}
                            component={CoachingSessions}
                        />

                        <CoachingRoute
                            exact
                            path={ROUTES.PLAYCOACHINGSESSIONS}
                            user={props.user}
                            component={PlayCoachingSessions}
                        />

                        <Route
                            exact path={ROUTES.TUTORIALS}
                            component={Tutorial}
                        />

                        <Route
                            exact path={ROUTES.DOWNLOADS}
                            component={Downloads}
                        />

                        <Route
                            path={ROUTES.DASHBOARD}
                            component={Dashboard}
                        />

                        <Route
                            path={ROUTES.CATEGORY}
                            render={(props) => <Category categories={categories} {...props} />}
                        />
                        <Route
                            path={ROUTES.REFERRAL}
                            user={props.user}
                            component={Affiliate}
                        />
                        <Route
                            path={ROUTES.REFERRALGRAPH}
                            component={ReferalAnalysisV2}
                        />
                        <Route
                            path={ROUTES.IMAGEPLAYER} >
                            <Play />
                        </Route>

                        <Route
                            path={ROUTES.SETTINGS}
                            component={SettingV2}
                        />

                        <Route
                            path={ROUTES.PERSONALIZEIMAGES}
                            component={PersonalizeImages}
                        />

                        {/* <Route
                            path={ROUTES.MYSESSIONS}
                            component={SessionsV2}
                        /> */}

                        <Route
                            path={ROUTES.MYSESSIONS}
                            component={SessionsV3}
                        />

                        <Route
                            path={ROUTES.IMAGES}
                            component={ImagePlayerV2}
                        />

                        <Route
                            exact
                            path={'/session/edit/:sessionId'}
                            component={EditSession}
                        />

                        <Route
                            exact
                            path={ROUTES.RECENTSESSIONS}
                            component={RecentSessions}
                        />

                        <Route
                            exact
                            path={'/404'}
                            component={ErrorPage}
                        />

                        <Route
                            exact
                            path={'/error'}
                            component={ServiceUnavailable}
                        />

                        <Route
                            component={ErrorPage}
                        />
                    </Switch>
                </div>
            </div>
        </div >
    )
}

// function renderInvites(props) {
//     if (!props.user || props.user.role.id === 2 || props.user.invite_parent) {
//         return <Redirect to="/404" />
//     }
//     else {
//         return (
//             <Switch>
//                 <Route exact path={ROUTES.INVITES} component={Invites} />
//             </Switch>
//         );
//     }
// }

// function renderUpgradeAccount(props) {
//     if (
//         props.user && (
//             props.user.role.id === 1 ||
//             props.user.role.id === 2 ||
//             props.user.role.id === 7 ||
//             props.user.invite_parent
//         )) {
//         return (
//             <Switch>
//                 <Route path={'/upgradeAccountLife'} component={UpgradeAccountLifeV2} />
//                 <Route path={'/upgradeAccountv2'} component={UpgradeAccountV2} />
//                 <Route path={ROUTES.UPGRADEACCOUNT} component={UpgradeAccountV3} />
//             </Switch>
//         )
//     }
//     else {
//         return <Redirect to="/404" />
//     }
// }

// function renderAdminArea(props, pathname) {
//     if (!props.user || props.user.role.id !== 1) {
//         return <Redirect to="/404" />
//     }
//     else {
//         return (
//             <Switch>
//                 <Route path={ROUTES.LEDGER} component={PaymentLedgerV2} />
//                 <Route path={ROUTES.PAYOUT} component={PayoutLedgerV2} />
//                 <Route path={ROUTES.ADMINDASHBOARD} component={AdminDashboardV2} />
//                 <Route path={ROUTES.USERADMINISTRATOR} component={UserManagement} />
//                 <Route path={ROUTES.SESSIONCURATOR} component={SessionCurator} />
//                 <Route path={ROUTES.MANUALFEATUREDSESSION} component={ManualFeaturedSessions} />
//             </Switch>
//         );
//     }
// }
// function renderProArea(props) {
//     if (!props.user || [1, 4].indexOf(props.user.role.id) < 0) {
//         return <Redirect to="/404" />
//     }
//     else {
//         return (
//             <Switch>
//                 <Route path={ROUTES.ANALYTICS} component={AnalyticsDashboard} />
//                 <Route path={ROUTES.CLIENTMANAGEMENT} component={UsersV2} />
//             </Switch>
//         );
//     }
// }

// function renderLoadingTags() {
//     <Switch>
//         <Route path={ROUTES.LEDGER} component={PaymentLedgerV2} />
//         <Route path={ROUTES.PAYOUT} component={PayoutLedgerV2} />
//         <Route path={ROUTES.ADMINDASHBOARD} component={AdminDashboardV2} />
//         <Route path={ROUTES.SESSIONCURATOR} component={SessionCurator} />
//     </Switch>
// }

export default DashboardRouter;
